import React, { useState, useEffect } from "react";
import Checkbox from "../../components/@vuexy/checkbox/CheckboxesVuexyV2";
import { Check } from "react-feather";
import "flatpickr/dist/themes/light.css";
import { Frown, Meh, Smile } from "react-feather";
import { commonpostApiFunction2 } from "../../resources/ApiController";
import { Button } from "reactstrap";
import classnames from "classnames";

const MorningSurvey = (props) => {
  let LoginData = JSON.parse(localStorage.getItem("spydrloginObject"));
  const [Data, setData] = useState({
    UserDetailID: LoginData.UserDetailID,
    FEEL: props.data && props.data.FEEL ? props.data.FEEL : "",
    PAIN: props.data && props.data.PAIN ? props.data.PAIN : '["No Pain"]',
    MorningSurveyDetailID_PK:
      props.data && props.data.MorningSurveyDetailID
        ? props.data.MorningSurveyDetailID
        : "",
  });
  const [PAIN, setPAIN] = useState(["No Pain"]);
  const [Show, setShow] = useState(true);
  const BodyParts = [
    "Right Shoulder",
    "Left Shoulder",
    "Right Arm",
    "Left Arm",
    "Right Hand",
    "Left Hand",
    "Right Hip",
    "Left Hip",
    "Right Leg",
    "Left Leg",
    "Right Knee",
    "Left Knee",
    "Right Foot",
    "Left Foot",
    "Upper Back",
    "Lower Back",
    "Neck",
    "Head",
    "No Pain",
  ];
  const addMorningSurveyDetail = async () => {
    let params = { ...Data, PAIN: PAIN.toString() };
    // console.log(params);
    commonpostApiFunction2(
      "addMorningSurveyDetail",
      params,
      async (response) => {
        if (
          response.data.addMorningSurveyDetailResponse &&
          response.data.addMorningSurveyDetailResponse[0].MorningSurveyDetailId
        ) {
          let MorningSurveyDetailID =
            response.data.addMorningSurveyDetailResponse[0]
              .MorningSurveyDetailId;
          console.log(Data);
          let newData = {
            ...Data,
            MorningSurveyDetailID_PK: MorningSurveyDetailID,
          };
          setData(await newData);

        }
      }
    );
  };

  useEffect(() => {
    if (Data && Show) {
      addMorningSurveyDetail();
    }
  }, [Data]);
  const handleToggle = async (name, value) => {
    let newData = { ...Data, [name]: value };
    if (name == "PAIN") {
      let pan = PAIN;
      let pn = PAIN;
      if (pan.includes(value)) {
        const index = pan.indexOf(value);
        if (index > -1) {
          // only splice array when item is found
          pan.splice(index, 1); // 2nd parameter means remove one item only
        }
        pn = [...pan];
      } else {
        if (value == "No Pain") {
          pn = ["No Pain"];
        } else {
          pn = [...pan, value];
        }
      }
      if (pn.length > 1) {
        const index = pn.indexOf("No Pain");
        if (index > -1) {
          pn.splice(index, 1);
        }
      }
      if (pn.length == 0) {
        pn = ["No Pain"];
      }
      setPAIN(await pn);
      newData = { ...newData, [name]: JSON.stringify(pn) };
    }
    setData(await { ...newData });
  };

  return (
    <>
      <div
        className={classnames("morn-survey text-center mt-5", {
          "d-none": !Show,
        })}
      >
        <h2>Welcome !</h2>
        <br />
        <h3>How did you feel when you woke up this morning?</h3>
        <br />
        <div className="emoji-color">
          <Smile
            size="40"
            className="emoj mx-2"
            color="green"
            cursor="pointer"
            onClick={(e) => {
              handleToggle("FEEL", 0);
              setShow(!Show);
            }}
          />
          <Smile
            size="40"
            className="emoj mx-2"
            color="#90EE90"
            cursor="pointer"
            onClick={(e) => {
              handleToggle("FEEL", 1);
              setShow(!Show);
            }}
          />
          <Meh
            size="40"
            className="emoj mx-2"
            color="#FFD580"
            cursor="pointer"
            onClick={(e) => {
              handleToggle("FEEL", 2);
              setShow(!Show);
            }}
          />
          <Frown
            size="40"
            className="emoj mx-2"
            color="orange"
            cursor="pointer"
            onClick={(e) => {
              handleToggle("FEEL", 3);
              setShow(!Show);
            }}
          />
          <Frown
            size="40"
            className="emoj mx-2"
            color="red"
            cursor="pointer"
            onClick={(e) => {
              handleToggle("FEEL", 4);
              setShow(!Show);
            }}
          />
        </div>
      </div>
      <div
        className={classnames("morn-survey text-center mt-5", {
          "d-none": Show,
        })}
      >
        <h2>Welcome !</h2>
        <h3>Did you wake up with any pain this morning?</h3>
        <div className="mt-2 ml-3 row">
          {BodyParts.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <div className="pl-2 col-md-4">
                  <Checkbox
                    color={
                      item == "No Pain"
                        ? "success"
                        : "primary"
                    }
                    icon={<Check className="vx-icon" size={16} />}
                    defaultChecked={true}
                    checked={PAIN.includes(item)}
                    onClick={(e) => handleToggle("PAIN", item)}
                    name={item}
                    id={item}
                    value={item}
                    label={item}
                  />
                </div>
              </React.Fragment>
            );
          })}
        </div>
        <Button className="btn btn-danger" onClick={props.clsmd}>
          Save
        </Button>
      </div>
    </>
  );
};
export default MorningSurvey;
