import React from "react"
import * as Icon from "react-feather";
import { history } from "../../src/history"
import { commonloginApiFunction } from "../resources/ApiController"


// 'Admin' ,  'SPYDR User' ,  'Registered User', 'AFE/Support Personnel'
const navigationConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ['Admin', 'SPYDR User', 'Registered User', 'AFE/Support Personnel'],
    navLink: "/home"
  },
  {
    id: "ManageSPYDRUsers",
    title: "Manage SPYDR Users",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ['Admin'],
    navLink: "/userlist"
  },

  {
    id: "ManageDevices",
    title: "Manage Devices",
    type: "item",
    icon: <Icon.Pocket size={20} />,
    permissions: ['Admin'],
    navLink: "/devicelist"
  },
  {
    id: "Manage User Device Mapping",
    title: "Manage User Device Mapping",
    type: "item",
    icon: <Icon.Share2 size={20} />,
    permissions: ['Admin'],
    navLink: "/userdevicemappinglist"
  },
  {
    id: "orm-questions",
    title: "ORM-Questions",
    type: "item",
    icon: <Icon.XSquare size={20} />,
    permissions: ['Admin'],
    navLink: "/orm-questions"
  },
  {
    id: "Squadrons",
    title: "Squadrons",
    type: "item",
    icon: <Icon.Anchor size={20} />,
    permissions: ['Admin'],
    navLink: "/Squadrons"
  },
  {
    id: "Aircraft",
    title: "Aircraft",
    type: "item",
    icon: <Icon.Navigation size={20} />,
    permissions: ['Admin'],
    navLink: "/aircraft"
  },
  {
    id: "CrewPosition",
    title: "Crew-Position",
    type: "item",
    icon: <Icon.AtSign size={20} />,
    permissions: ['Admin'],
    navLink: "/CrewPosition"
  },
  {
    id: "flight-callSign",
    title: "Flight CallSign",
    type: "item",
    icon: <Icon.Send size={20} />,
    permissions: ['Admin'],
    navLink: "/flight-callSign"
  },
  {
    id: "ManageORMs",
    title: "Manage ORMs",
    type: "item",
    icon: <Icon.Grid size={20} />,
    permissions: ['Admin'],
    navLink: "/manage-orms"
  },
  {
    id: "CommanderDashboard",
    title: "Commander Dashboard",
    type: "item",
    icon: <Icon.Star size={20} />,
    permissions: ['Admin', 'SPYDR User'],
    navLink: "/commader-dashboard"
  },
  {
    id: "ORMDashBoard",
    title: "ORM Dashboard",
    type: "item",
    icon: <Icon.PieChart size={20} />,
    permissions: ['Admin', 'SPYDR User'],
    navLink: "/ORM-dashboard"
  },
  {
    id: "OpsSupDashboard",
    title: "Ops Sup Dashboard",
    type: "item",
    icon: <Icon.Airplay size={20} />,
    permissions: ['Admin', 'SPYDR User'],
    navLink: "/ops-sup-dashboard"
  },
  // {
  //   id: "ORMDashBoard1",
  //   title: "ORM Dashboard 1",
  //   type: "item",
  //   icon: <Icon.Airplay size={20} />,
  //   permissions: ['Admin', 'SPYDR User'],
  //   navLink: "/ORM-dashboard1"
  // },
  {
    id: "StartORM",
    title: "Start ORM",
    type: "item",
    icon: <Icon.UserPlus size={20} />,
    permissions: ['Admin', 'SPYDR User', 'Registered User', 'AFE/Support Personnel'],
    navLink: "/start-orm"
  },
  {
    id: "ReviewORM",
    title: "Review ORM",
    type: "item",
    icon: <Icon.Clipboard size={20} />,
    permissions: ['Admin', 'SPYDR User', 'Registered User', 'AFE/Support Personnel'],
    navLink: "/review-orm"
  },

  {
    id: "CloseOutFlightORM",
    title: "Close-Out Flight ORM",
    type: "item",
    icon: <Icon.UserCheck size={20} />,
    permissions: ['Admin', 'SPYDR User', 'Registered User', 'AFE/Support Personnel'],
    navLink: "/close-out-flight-orm",
    badge: "warning",
    // badgeText: getcount(),
  },
  {
    id: "ReviewCloseOutFlightORM",
    title: "Review-Close-Out Flight ORM",
    type: "item",
    icon: <Icon.CheckSquare size={20} />,
    permissions: ['Admin', 'SPYDR User', 'Registered User', 'AFE/Support Personnel'],
    navLink: "/review-close-out-flight-orm"
  },
  // {
  //   id: "GarminUserData",
  //   title: "Garmin Data",
  //   type: "item",
  //   icon: <Icon.File size={20} />,
  //   permissions: ['Admin', 'SPYDR User', 'Registered User', 'AFE/Support Personnel'],
  //   navLink: "/garminconnect"
  // },
  {
    id: "Logout",
    title: "Logout",
    type: "item",
    icon: <Icon.Power size={20} />,
    permissions: ['Admin', 'SPYDR User', 'Registered User', 'AFE/Support Personnel'],
    navLink: "/logout"
  }
]


export default navigationConfig

