const apiConfig = {

  // api_url: "https://informics.net/spydrapi/public/index.php/",
  // PostFlightQuestionDetail: "https://informics.net/spydrapi/public/PostFlight/PostFlightQuestionDetail.json"
  // api_url: "http://103.212.121.241/spydrapi/public/index.php/",
  // PostFlightQuestionDetail: "http://103.212.121.241/spydrapi/public/PostFlight/PostFlightQuestionDetail.json"
  api_url: "https://spydrapi.spydrdata.com/public/index.php/",
  PostFlightQuestionDetail: "https://spydrapi.spydrdata.com/public/PostFlight/PostFlightQuestionDetail.json"

}

export default apiConfig
