import React, { useState } from 'react'
import axios from 'axios';
import apiConfig from "../configs/apiConfig";
import { history } from "../history";

import qs from 'qs';
const userData = localStorage.getItem('spydrloginObject') && localStorage.getItem('spydrloginObject') != "undefined" ? JSON.parse(localStorage.getItem('spydrloginObject')) : "";
let Token = userData?.token ? userData?.token : "";
var path = history.location.pathname;
const locationArr = ["/login", "/forgotpassword", "/resetpassword", "/signup"];

const getParam = (params) => {

    if (!userData) {
        const userData = JSON.parse(localStorage.getItem('spydrloginObject'));
        Token = userData?.token;
        return { ...params, "token": Token }
    } else {
        return params;
    }
}
const setToken = () => {
    const userData = JSON.parse(localStorage.getItem('spydrloginObject'));
    if (Token == '' && userData?.token) {
        Token = userData?.token;
    }
}
function commonApiFunction(params, callback, callbackError) {

    params = getParam(params)
    let tm = new Date();
    let tmt = tm.getTime();
    axios.post(apiConfig.api_url + "?ct=" + tmt, qs.stringify(params), { timeout: 30000 })
        .then((response) => {
            callback(response);
        }).catch((error) => {
            callbackError(error);
        })
}
export function commonloginApiFunction(methodname, params, callback, callbackError) {

    let tm = new Date();
    let tmt = tm.getTime();
    axios.post(apiConfig.api_url + methodname + "?ct=" + tmt, qs.stringify(params), { timeout: 30000 })
        .then((response) => {
            callback(response);
        }).catch((error) => {
            callbackError(error);
        })
}
export function commonpostApiFunction(methodname, params, callback, callbackError) {

    let tm = new Date();
    let tmt = tm.getTime();
    setToken(params);
    axios.post(
        apiConfig.api_url + methodname + "?ct=" + tmt, qs.stringify(params),
        {
            headers: {
                'Authorization': `Bearer ${Token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }
    )
        .then((response) => {
            callback(response);

        }).catch((error) => {
            if (error?.response?.status == 401 && error.response.data == 'Authorisation Failed' && !locationArr.includes(path)) {
                console.log(error.response.data);
                console.log(error.response.status);
                window.location.href = '/login';
            }
            callbackError(error);

        })

}
export function commonpostApiFunction2(methodname, params, callback) {

    setToken(params);
    let tm = new Date();
    let tmt = tm.getTime();
    axios.post(
        apiConfig.api_url + methodname + "?ct=" + tmt, qs.stringify(params),
        {
            headers: {
                'Authorization': `Bearer ${Token}`,
            }
        }
    )
        .then((response) => {
            callback(response);
        }).catch((error) => {
            // callbackError(error);
            if (error?.response?.status == 401 && error.response.data == 'Authorisation Failed' && !locationArr.includes(path)) {
                console.log(error.response.data);
                console.log(error.response.status);
                window.location.href = '/login';
            }

        })
}
export function commongetApiFunction(methodname, params, callback, callbackError) {

    params = getParam(params);
    let tm = new Date();
    let tmt = tm.getTime();
    axios.get(apiConfig.api_url + methodname + "?ct=" + tmt, qs.stringify(params), { timeout: 30000 })
        .then((response) => {
            callback(response);
        }).catch((error) => {
            callbackError(error);
        })
}
export function commonputApiFunction(methodname, params, callback, callbackError) {

    params = getParam(params);
    let tm = new Date();
    let tmt = tm.getTime();
    axios.put(apiConfig.api_url + methodname + "?ct=" + tmt, qs.stringify(params), { timeout: 30000 })
        .then((response) => {
            callback(response);
        }).catch((error) => {
            callbackError(error);
        })
}
export function commonslimApiFunction(methodname, params, callback, callbackError) {

    params = getParam(params)
    let tm = new Date();
    let tmt = tm.getTime();
    axios.post(apiConfig.api_url + methodname + "?ct=" + tmt, qs.stringify(params), { timeout: 30000 })
        .then((response) => {
            callback(response);
        }).catch((error) => {
            callbackError(error);
        })
}
export function uploadImageFileFunctionRest(methodname, formData, callback, token = Token) {

    setToken();
    let tm = new Date();
    let tmt = tm.getTime();
    axios.post(apiConfig.api_url + methodname + "?ct=" + tmt, formData, {
        headers: {
            'content-type': 'multipart/form-data',
            'Authorization': `Bearer ${Token}`,
        }
    }).then((response) => {
        callback(response);
    }).catch(error => { console.log(error); })
}
function extraParamApiFunction(params, callback, variable) {
    params = getParam(params)
    //  console.log(variable)
    axios.post(apiConfig.api_url, qs.stringify(params), { timeout: 30000 })
        .then((response) => {
            callback(response, variable);
        }).catch((error) => {
            console.log(error)
            // callbackError(error);
        })
}
function uploadImageFileFunction(formData, callback) {

    axios({
        method: 'post',
        url: apiConfig.api_url,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
    })
        .then((response) => {
            callback(response);
        }).catch(error => console.log(error))
}

export { uploadImageFileFunction, extraParamApiFunction }
export default commonApiFunction
