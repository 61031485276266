import React from "react"
import * as Icon from "react-feather"
import FontAwesomeIcon from "react-fontawesome"
const optionsMenuConfig = [
  
  

]

export default optionsMenuConfig
