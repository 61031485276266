import React, { useEffect, useState } from "react";
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Badge,
  Media,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import * as Icon from "react-feather";
import classnames from "classnames";
import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent";
import MorningSurvey from "../../../views/pages/MorningSurvey";
import { convertDate, updateLoginObject, saveFCMToke } from "../../../resources/customHelper";
import { history } from "../../../history";
import requestNotificationPermission from "../../../firebase";
import { commonpostApiFunction } from "../../../resources/ApiController";

const UserDropdown = (props) => {
  const logeduserdata = JSON.parse(localStorage.getItem("spydrloginObject"))
    ? JSON.parse(localStorage.getItem("spydrloginObject"))
    : "";
  const userRole = logeduserdata ? logeduserdata?.UserRoleName : "";
  const handleLogout = () => {
    history.push("/logout");
  };
  return (
    <DropdownMenu right style={{ zIndex: '9' }}>
      {/* {console.log(user, "useruseruseruser")} */}
      <DropdownItem
        href="/changepassword"
      // target="_blank"
      >
        <Icon.Key size={14} className="mr-50" />
        <span className="align-middle">Change Password</span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem
        href="/contactus"
      // target="_blank"
      >
        <Icon.HelpCircle size={14} className="mr-50" />
        <span className="align-middle">Contact Us</span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem
        href="/userprofile"
      // target="_blank"
      >
        <Icon.UserPlus size={14} className="mr-50" />
        <span className="align-middle">Edit Profile</span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem
        href="/connecteddevices"
      // target="_blank"
      >
        <Icon.Tablet size={14} className="mr-50" />
        <span className="align-middle">Connected Devices</span>
      </DropdownItem>
      {userRole != "Registered User" ? <DropdownItem divider /> : ""}
      {userRole != "Registered User" ? (
        <DropdownItem className="w-100" href="/deviceregistration">
          <Icon.Tablet size={14} className="mr-50" />
          <span className="align-middle">My Registered Devices</span>
        </DropdownItem>
      ) : (
        ""
      )}
      <DropdownItem divider />
      <DropdownItem className="w-100" onClick={(e) => handleLogout()}>
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  );
};
class NavbarUser extends React.PureComponent {
  constructor(props) {
    super(props)
    const loginObject = JSON.parse(localStorage.getItem("spydrloginObject"));
    let globalFilter = localStorage.getItem('globalfilterdata') ? JSON.parse(localStorage.getItem('globalfilterdata')) : {
      TimePeriod: "A",
      Squadron: "A",
      Aircraft: "A",
    };
    this.state = {
      navbarSearch: false,
      suggestions: [],
      username: "",
      userData: [],
      userImg: loginObject?.DocumentRelativePath,
      userimg2: loginObject?.DefaultAircraftImage,
      MorningSurveyModal: false,
      MorningSurveyData: "",
      NotificationData: [],
      NotificationPopup: true,
      globalFilter: { ...globalFilter },
    };
    // this.logout=this.logout.bind(this)
  }
  handleUserDetails = () => {
    const { user } = this.props;
    const loginObject = JSON.parse(localStorage.getItem("spydrloginObject"));
    if (!loginObject) {
      commonpostApiFunction(
        "getLoginObjectDetail",
        { EmailID: user && user.email },
        (response) => {
          const finalData =
            response && response.data && response.data.LoginObjectDetail[0];
          localStorage.setItem("spydrloginObject", JSON.stringify(finalData));
          this.setState({ userData: finalData });
        },
        (error) => console.log(error)
      );
    } else {
      this.setUserData();
    }
  };
  //  @@ Set state User ProfileURL and Username
  handleResponse = async (response) => {
    if (
      response &&
      response.data &&
      response.data.UserProfileDetail &&
      response.data.UserProfileDetail.length > 0
    ) {
      this.setState({
        userImg: response.data.UserProfileDetail[0]?.DocumentRelativePath,
        userimg2: response.data.UserProfileDetail[0]?.DefaultAircraftImage,
      });
    }
  };
  setUserData = () => {
    this.state.userData = JSON.parse(localStorage.getItem("spydrloginObject"));
    if (this.state.userData != null) {
      this.setState({ username: this.state.userData?.UserName });
    }
    commonpostApiFunction(
      "getUserProfileDetail",
      {
        UserDetailID: this.state.userData
          ? this.state.userData?.UserDetailID
          : 0,
      },
      this.handleResponse,
      this.onError
    );
  };
  componentDidMount() {
    // !localStorage.getItem("FCMToken") && history.location.pathname != '/login' && requestNotificationPermission();

    if (!localStorage.getItem("spydrloginObject") || JSON.parse(localStorage.getItem("spydrloginObject"))?.toke) {
      updateLoginObject()
    }
    // if (!localStorage.getItem("FCMToken") && JSON.parse(localStorage.getItem("spydrloginObject"))?.UserDetailID) {
    //   saveFCMToke();
    // }
    this.handleUserDetails();
    this.getMorningSurveyDetail();
    this.getEditUserDetailNotification();
  }
  componentDidUpdate(prevProps, prevState) {
    if (!localStorage.getItem("spydrloginObject") || JSON.parse(localStorage.getItem("spydrloginObject"))?.toke) {
      updateLoginObject();
    }
    this.handleUserDetails();
    if (prevProps.activePath !== this.props.activePath) {
      this.setState({ NotificationData: [] })
      this.getMorningSurveyDetail();
      this.getEditUserDetailNotification();
    }
    localStorage.getItem("FCMToken") && JSON.parse(localStorage.getItem("spydrloginObject"))?.UserDetailID && !localStorage.getItem("FCMTokenSave") && saveFCMToke();
    !localStorage.getItem("FCMToken") && history.location.pathname != '/login' && !localStorage.getItem("FCMTokenSave") && requestNotificationPermission();
  }
  // @@ HANDLE SEARCH
  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    });
  };
  closeModal = async () => {
    await this.setState({
      MorningSurveyModal: false,
      NotificationPopup: true,
    });
  };
  getEditUserDetailNotification = () => {
    commonpostApiFunction(
      "getEditUserDetailNotification",
      {
        UserDetailID: JSON.parse(localStorage.getItem("spydrloginObject"))?.UserDetailID
      },
      async (response) => {
        if (response?.data?.Notification[0]?.IsSuccess == 1) {
          this.setState({ NotificationData: [...this.state.NotificationData, ...response?.data?.Notification] })
          updateLoginObject();
        }
      },
      (error) => console.log(error)
    );
  }
  getMorningSurveyDetail = async () => {
    let todayDate = convertDate("");
    let params = {
      UserDetailID: JSON.parse(localStorage.getItem("spydrloginObject"))?.UserDetailID,
      CreatedDate: todayDate,
    };
    commonpostApiFunction(
      "getMorningSurveyDetail",
      params,
      async (response) => {
        if (
          response.data.MorningSurveyDetail &&
          response.data.MorningSurveyDetail.length > 0 &&
          response.data.MorningSurveyDetail[0].MorningSurveyDetailID
        ) {
          this.setState(
            await {
              MorningSurveyModal: false,
              MorningSurveyData: response.data.MorningSurveyDetail[0],
              NotificationPopup: false,
            }
          );
        } else {
          var date = new Date();
          var hour = ("0" + date.getHours()).slice(-2);
          if (hour < 10) {
            this.setState({
              MorningSurveyModal: true,
              NotificationPopup: false,
            });
          } else {
            this.setState({
              MorningSurveyModal: false,
              NotificationPopup: true,
              NotificationData: [...this.state.NotificationData, {
                NotificationID: null,
                IsSuccess: null,
                "Comment": "morning servery",
                NewValue: null,
                NotificationTypeID: 3,
                OldValue: null,
                PreFlightSurveyDetailID: null,
                UserDetailID: null,
                UserEdit: null,
              }
              ]
            });
          }
        }
      },
      (error) => console.log(error)
    );
  };
  globalFilter = (key, value) => {
    let newdata = {
      ...this.state.globalFilter,
      [key]: value,
    };
    this.setState({
      globalFilter: newdata,
    });
    localStorage.setItem('globalfilterdata', JSON.stringify(newdata));
    history.push({
      globalfilterdata: {
        ...newdata,
      },
    });
  };
  updateNotificationState = (e, isOpen) => {
    let count = 1;
    isOpen === false && this.state?.NotificationData?.length > 0 &&
      this.state.NotificationData.map(data => {
        data.NotificationTypeID != "2" && data.NotificationTypeID != "3" &&
          commonpostApiFunction(
            "upateNotificationStatus",
            { "NotificationID": data.NotificationID },
            (response) => count++,
            (error) => console.log(error)
          );
      });
    if (isOpen === false && !this.state.MorningSurveyModal) {
      // console.log(isOpen, count, this.state?.NotificationData?.length)
      this.setState({ NotificationData: [] });
      this.getMorningSurveyDetail();
      this.getEditUserDetailNotification();
    }
  }

  render() {
    return (
      <>
        {/* Morning Survey Modal Detail start Here   */}
        {this.state.MorningSurveyModal && (
          <Modal
            isOpen={this.state.MorningSurveyModal}
            toggle={this.closeModal}
            className="modal-lg"
          >
            <ModalHeader
              toggle={this.closeModal}
              className="bg-primary modal-custtitle"
            >
              Morning Survey Detail
            </ModalHeader>
            <ModalBody>
              <MorningSurvey
                clsmd={async () => {
                  this.setState({
                    MorningSurveyModal: false,
                    NotificationPopup: false,
                    NotificationData: []
                  })
                  this.getMorningSurveyDetail();
                  this.getEditUserDetailNotification();
                }}
                data={this.state.MorningSurveyData}
              />
            </ModalBody>
          </Modal>
        )}
        {/* Morning Survey Detail Modal endeHere   */}
        {/* {(history.location.pathname == "/home" ||
          history.location.pathname == "/ORM-dashboard" ||
          history.location.pathname == "/commader-dashboard") && ( */}
        <Row className="filterwidth">
          <Col md={6} lg={4}>
            <div
              className="btn-group d-flex filterbtn"
              role="group"
              aria-label="Basic example"
            >
              <button
                type="button"
                onClick={() => {
                  this.globalFilter("TimePeriod", "W");
                }}
                className={
                  this.state.globalFilter.TimePeriod == "W"
                    ? "active btn p-1 btn-group-sm "
                    : "btn p-1 btn-group-sm"
                }
              >
                Week
              </button>
              <button
                type="button"
                onClick={() => {
                  this.globalFilter("TimePeriod", "M");
                }}
                className={
                  this.state.globalFilter.TimePeriod == "M"
                    ? "active btn p-1 btn-group-sm "
                    : "btn p-1 btn-group-sm"
                }
              >
                Month
              </button>
              <button
                type="button"
                onClick={() => {
                  this.globalFilter("TimePeriod", "Y");
                }}
                className={
                  this.state.globalFilter.TimePeriod == "Y"
                    ? "active btn p-1 btn-group-sm "
                    : "btn p-1 btn-group-sm"
                }
              >
                Year
              </button>
              <button
                type="button"
                onClick={() => {
                  this.globalFilter("TimePeriod", "A");
                }}
                className={
                  this.state.globalFilter.TimePeriod == "A"
                    ? "active btn p-1 btn-group-sm "
                    : "btn p-1 btn-group-sm"
                }
              >
                All Time
              </button>
            </div>
          </Col>
          <Col md={3} lg={4}>
            <div
              className="btn-group d-flex filterbtn"
              role="group"
              aria-label="Basic example"
              style={history.location.pathname === "/commader-dashboard" ? { pointerEvents: "none", opacity: "0.4" } : { display: "inline-block" }}
            >
              <button
                type="button"
                onClick={() => {
                  this.globalFilter("Squadron", this.state.userData?.Squadron);
                }}
                className={
                  this.state.globalFilter.Squadron ==
                    this.state.userData?.Squadron
                    ? "active btn p-1 btn-group-sm "
                    : "btn p-1 btn-group-sm"
                }
              >
                My Squadron
              </button>
              <button
                type="button"
                onClick={() => {
                  this.globalFilter("Squadron", "A");
                }}
                className={
                  this.state.globalFilter.Squadron == "A"
                    ? "active btn p-1 btn-group-sm "
                    : "btn p-1 btn-group-sm"
                }
              >
                All Spydr Users
              </button>
            </div>
          </Col>
          <Col md={3} lg={4}>
            <div
              className="btn-group d-flex filterbtn"
              role="group"
              aria-label="Basic example"
            >
              <button
                type="button"
                onClick={() => {
                  this.globalFilter(
                    "Aircraft",
                    this.state.userData?.AircraftName
                  );
                }}
                className={
                  this.state.globalFilter.Aircraft ==
                    this.state.userData?.AircraftName
                    ? "active btn p-1 btn-group-sm "
                    : "btn p-1 btn-group-sm"
                }
              >
                My Aircraft
              </button>
              <button
                type="button"
                onClick={() => {
                  this.globalFilter("Aircraft", "A");
                }}
                className={
                  this.state.globalFilter.Aircraft == "A"
                    ? "active btn p-1 btn-group-sm "
                    : "btn p-1 btn-group-sm"
                }
              >
                All Aircraft Type
              </button>
            </div>
          </Col>
        </Row>
        {/* )} */}
        <ul className="nav navbar-nav ml-auto navbar-nav-user float-right">
          <NavItem className="nav-search" onClick={this.handleNavbarSearch}>
            {/* <NavLink className="nav-link-search">
            <Icon.Search size={21} data-tour="search" />
          </NavLink> */}
            <div
              className={classnames("search-input", {
                open: this.state.navbarSearch,
                "d-none": this.state.navbarSearch === false,
              })}
            >
              <div className="search-input-icon">
                <Icon.Search size={17} className="primary" />
              </div>
              <Autocomplete
                className="form-control"
                suggestions={this.state.suggestions}
                filterKey="title"
                filterHeaderKey="groupTitle"
                grouped={true}
                placeholder="Explore Vuexy..."
                autoFocus={true}
                clearInput={this.state.navbarSearch}
                externalClick={(e) => {
                  this.setState({ navbarSearch: false });
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 27 || e.keyCode === 13) {
                    this.setState({
                      navbarSearch: false,
                    });
                    this.props.handleAppOverlay("");
                  }
                }}
                customRender={(
                  item,
                  i,
                  filteredData,
                  activeSuggestion,
                  onSuggestionItemClick,
                  onSuggestionItemHover
                ) => {
                  const IconTag = Icon[item.icon ? item.icon : "X"];
                  return (
                    <li
                      className={classnames("suggestion-item", {
                        active: filteredData.indexOf(item) === activeSuggestion,
                      })}
                      key={i}
                      onClick={(e) => onSuggestionItemClick(item.link, e)}
                      onMouseEnter={() =>
                        onSuggestionItemHover(filteredData.indexOf(item))
                      }
                    >
                      <div
                        className={classnames({
                          "d-flex justify-content-between align-items-center":
                            item.file || item.img,
                        })}
                      >
                        <div className="item-container d-flex">
                          {item.icon ? (
                            <IconTag size={17} />
                          ) : item.file ? (
                            <img
                              src={item.file}
                              height="36"
                              width="28"
                              alt={item.title}
                            />
                          ) : item.img ? (
                            <img
                              className="rounded-circle mt-25"
                              src={item.img}
                              height="28"
                              width="28"
                              alt={item.title}
                            />
                          ) : null}
                          <div className="item-info ml-1">
                            <p className="align-middle mb-0">{item.title}</p>
                            {item.by || item.email ? (
                              <small className="text-muted">
                                {item.by
                                  ? item.by
                                  : item.email
                                    ? item.email
                                    : null}
                              </small>
                            ) : null}
                          </div>
                        </div>
                        {item.size || item.date ? (
                          <div className="meta-container">
                            <small className="text-muted">
                              {item.size
                                ? item.size
                                : item.date
                                  ? item.date
                                  : null}
                            </small>
                          </div>
                        ) : null}
                      </div>
                    </li>
                  );
                }}
                onSuggestionsShown={(userInput) => {
                  if (this.state.navbarSearch) {
                    this.props.handleAppOverlay(userInput);
                  }
                }}
              />
              <div className="search-input-close">
                <Icon.X
                  size={24}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({
                      navbarSearch: false,
                    });
                    this.props.handleAppOverlay("");
                  }}
                />
              </div>
            </div>
          </NavItem>
          {this.state.NotificationData && (
            <UncontrolledDropdown
              tag="li"
              className="dropdown-notification nav-item"
              onToggle={(e, isOpen) => this.updateNotificationState(e, isOpen)}
            >
              <DropdownToggle tag="a" className="nav-link nav-link-label" >
                <Icon.Bell size={21} />
                <Badge pill color="primary" className="badge-up">
                  {this.state.NotificationData.length}
                </Badge>
              </DropdownToggle>
              <DropdownMenu tag="ul" right className="dropdown-menu-media">
                <li className="dropdown-menu-header">
                  <div
                    className="dropdown-header mt-0"
                    style={{ width: " 200px" }}
                  >
                    <h3 className="text-white">{this.state.NotificationData.length} New</h3>
                    <span className="notification-title"> Notifications</span>
                  </div>
                </li>
                <PerfectScrollbar
                  className="media-list overflow-auto position-relative"
                  options={{
                    wheelPropagation: false,
                  }}
                >
                  {
                    this.state.NotificationPopup &&
                    <div className="d-flex justify-content-between">
                      <Media className="d-flex align-items-start">
                        <Media left href="#">
                          <Icon.PlusSquare
                            className="font-medium-5 primary"
                            size={21}
                          />
                        </Media>
                        <Media
                          body
                          onClick={() =>
                            this.setState({
                              MorningSurveyModal: true
                            })
                          }
                        >
                          <Media
                            heading
                            className="primary media-heading"
                            tag="h6"
                          >
                            Your SPYDR Morning Survey is still waiting to be completed.
                          </Media>
                          <p className="notification-text">
                            Please fill out your morning survey.
                          </p>
                        </Media>
                      </Media>
                    </div>
                  }

                  {
                    this.state.NotificationData &&

                    this.state.NotificationData.map(data => {
                      return (
                        data.NotificationTypeID != "3" &&
                        < div className="d-flex justify-content-between">
                          <Media className="d-flex align-items-start">
                            <Media left href="#">
                              <Icon.PlusSquare className="font-medium-5 primary" size={21} />
                            </Media>
                            <Media body
                              onClick={() => { data.NotificationTypeID == "2" && history.push("close-out-flight-orm") }}
                            >
                              <Media heading className="primary media-heading" tag="h6" >
                                {data.Comment}
                              </Media>
                            </Media>
                          </Media>
                        </div>
                      )
                    })
                  }
                </PerfectScrollbar>
                {/* <li className="dropdown-menu-footer">
              <DropdownItem tag="a" className="p-1 text-center">
                <span className="align-middle">Read all notifications</span>
              </DropdownItem>
            </li> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
          <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
            <DropdownToggle tag="a" className="nav-link dropdown-user-link">
              <div className="user-nav d-sm-flex d-none">
                <span className="user-name text-bold-600">
                  {this.state.userData != null
                    ? this.state.userData?.FirstName
                    : ""}
                </span>
                {/* <span className="user-status"> */}
                <span className="user-name text-bold-600">
                  {this.state.userData != null
                    ? this.state.userData?.LastName
                    : ""}</span>
              </div>
              <span data-tour="user">
                <img onError={(e) => { e.target.src = "/images/defaultProfile.jpg" }} src={this.state.userImg && this.state.userImg != "null" ? this.state.userImg : this.state.userimg2 ? this.state.userimg2 : "/images/defaultProfile.jpg"} className="round"
                  height="40"
                  width="40"
                  alt="avatar" />
              </span>
            </DropdownToggle>
            <UserDropdown {...this.props} />
          </UncontrolledDropdown>
        </ul >
      </>
    );
  }
}
export default NavbarUser;
