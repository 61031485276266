import React from "react"
import { Navbar, Row, Col  } from "reactstrap"
import classnames from "classnames"
import NavbarBookmarks from "./NavbarBookmarks"
import NavbarUser from "./NavbarUser"
import userImg from "../../../assets/img/portrait/small/avatar-s-50.png"
import { useHistory } from "react-router-dom";
const ThemeNavbar = props => {
  const history = useHistory();
  const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"]
  const navbarTypes = ["floating", "static", "sticky", "hidden"]


  const logeduserdata = JSON.parse(localStorage.getItem('spydrloginObject')) ? JSON.parse(localStorage.getItem('spydrloginObject')) : "";
  const userRole = logeduserdata ? logeduserdata?.UserRoleName : "";
  return (

    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            "scrolling": props.horizontal && props.scrolling

          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
          <Row>
            <Col md={12} lg={12} className="text-center text-white" style={{ background: "darkred" }}>
              <a href="/contactus" className="text-center text-white">This software is still in testing and has not yet been fielded. Click here to submit feedback!</a>
            </Col>
            </Row>
                <div
              className="navbar-collapse  justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="bookmark-wrapper">
                {/* < BackButton /> */}
                {/* {path != "/home" && userRole != "Registered User" ? < BackButton /> : ""} */}
                <NavbarBookmarks
                  sidebarVisibility={props.sidebarVisibility}
                  handleAppOverlay={props.handleAppOverlay}
                />
              </div>
              {/* <NavLink to="/"> */}
              {props.horizontal ? (
                <div className="logo d-flex align-items-center">

                  {userRole != "Registered User" ?
                    (<a href="/home" className="navbar-brand">
                      <div className="brand-logo mr-50"></div>
                      {/* <h2 className="text-primary brand-text mb-0">SPYDR WEB1</h2> */}
                    </a>)
                    :
                    (
                      <div className="brand-logo mr-50"></div>
                      // <h2 className="text-primary brand-text mb-0">SPYDR WEB1</h2>
                    )
                  }

                </div>
              ) : null}
              {/* </NavLink> */}
              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userName="John Doe"
                userImg={userImg}
                activePath={history.location.pathname}
              />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  )
}


export default ThemeNavbar
