import { commonpostApiFunction, commonpostApiFunction2 } from "./ApiController";
export function convertDate(params = '', type = 'MDY', saprator = "-") {
  // if (params != "") { params = Date.parse(params) };
  var date = params != '' ? new Date(params) : new Date(),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  // return [date.getFullYear(), mnth, day].join("-");
  if (type == 'MDY') {
    return mnth + saprator + day + saprator + date.getFullYear();
  }
  if (type == 'MDYHIS') {
    return mnth + saprator + day + saprator + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  }
  if (type == 'YMDHIS') {
    return date.getFullYear() + saprator + mnth + saprator + day + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  }
  if (type == 'YMD') {
    return date.getFullYear() + saprator + mnth + saprator + day;
  }
  if (type == 'DMY') {
    return day + saprator + mnth + saprator + date.getFullYear();
  }
  if (type == 'YMDTHIS') {
    return date.getFullYear() + saprator + mnth + saprator + day + "T23:59:59";
    // return date.getFullYear() + saprator + mnth + saprator + day + "T" + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  }
}
export function convertDateToUTC(params, type = 'MDY', saprator = "-") {
  // if (params != "") { params = Date.parse(params) };
  var isoDateString = new Date(params).toISOString();
  var date = params != '' ? new Date(params) : new Date(),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  // return [date.getFullYear(), mnth, day].join("-");
  if (type == 'YMDTHIS') {
    var convdate = date.getFullYear() + saprator + mnth + saprator + day + "T23:59:59";
    // return date.getFullYear() + saprator + mnth + saprator + day + "T" + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    let utcdatetime = new Date(convdate).toISOString();
    return utcdatetime;
  }
}
export function convertDateDMY(params) {
  var date = new Date(params);
  let mnth = ("0" + (date.getMonth() + 1)).slice(-2);
  let day = ("0" + date.getDate()).slice(-2);
  let yr = ("0" + date.getFullYear()).slice(-2);
  // return [date.getFullYear(), mnth, day].join("-");
  return day + "-" + mnth + "-" + yr;
}
export function generateRandomColor() {
  const randomColor = Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, '0');
  return `#${randomColor}`;
}
export function convertTime(params) {
  var date = new Date(params);
  var hour = ("0" + date.getHours()).slice(-2);
  var minute = ("0" + date.getMinutes()).slice(-2);
  var seconds = ("0" + date.getSeconds()).slice(-2);
  // return [date.getFullYear(), mnth, day].join("-");
  return hour + ":" + minute + ":" + seconds;
}
export function getMinutPlusTime(d, timeToAdd) {
  if (d.length) { d = d[0] }
  // console.log(d);
  var date = new Date(d.getTime() + (timeToAdd * 60000));
  var hour = ("0" + date.getHours()).slice(-2);
  var minute = ("0" + date.getMinutes()).slice(-2);
  var seconds = ("0" + date.getSeconds()).slice(-2);
  return hour + ":" + minute;
}
export function findinJSON(obj, searchField, searchVal) {
  // console.log(obj, searchField, searchVal);
  let response = false;
  obj.map((item, i) => {
    if (item[`${searchField}`] == searchVal) {
      response = i;
    }
  });
  return response;
}
export function msToHMS(ms) {
  if (ms) {
    let seconds = ms / 1000;
    const hours = parseInt(seconds / 3600).toString().padStart(2, '0');
    seconds = seconds % 3600;
    const minutes = parseInt(seconds / 60).toString().padStart(2, '0');
    seconds = seconds % 60;
    return hours + ":" + minutes;
  } else {
    return "00:00";
  }
}
export function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return convertDate(result, 'YMD');
}
export function checkEligibilityForMissionOrEnv(editID) {
  if (localStorage.getItem("basicData")) {
    let basicData = JSON.parse(localStorage.getItem("basicData"));
    if (!editID && basicData.FormationPosition == 1 && basicData.GroupSelected) { return true; }
    else if (!editID && !basicData.GroupSelected) { return true; }
    else if (editID && basicData.FormationPosition == 1) { return true; }
    else { return false }
  }
  else { return false }
}
export function blankLocalStorage() {
  localStorage.removeItem("reviewORMData");
  localStorage.removeItem("ORMData");
  localStorage.removeItem("basicData");
  localStorage.removeItem("FormationPosition");
  localStorage.removeItem("PreFlightSurveyDetailID");
  localStorage.removeItem("envTotal");
  localStorage.removeItem("missionTotal");
  localStorage.removeItem("personTotal");
  localStorage.removeItem("personData");
  localStorage.removeItem("missionData");
  localStorage.removeItem("envData");
  localStorage.removeItem("PreFlightItemDetail");
  localStorage.removeItem("stateOptionsClone");
  localStorage.removeItem("newthreatData");
  localStorage.removeItem("option");
  localStorage.removeItem("missiondata");
  localStorage.removeItem("envdata");
  localStorage.removeItem("persondata");
  return true;
}
export function updateLoginObject(daata = '') {
  let userData = localStorage.getItem("spydrloginObject") ? JSON.parse(localStorage.getItem("spydrloginObject")) : daata;
  if (userData != "" && userData != undefined) {
    commonpostApiFunction('getUserDetailByID',
      { UserDetailID: userData?.UserDetailID, tokensExpireOn: userData?.tokensExpireOn },
      async (res) => {
        localStorage.setItem("spydrloginObject", JSON.stringify(res.data.UserDetailByID[0]));
      },
      (Error) => {
        console.log(Error)
      });
    return true;
  }
}
export function getcount() {
  var recordcount = '';
  if (localStorage.getItem('spydrloginObject')) {
    const UserDetailID = JSON.parse(localStorage.getItem('spydrloginObject')).UserDetailID;
    commonpostApiFunction2('getPreFlightItemWithoutPostFlightRecordCount',
      {
        UserDetailID: UserDetailID,
        GlobalFilter: '',
        OffsetValue: 0,
        RowCount: '',
      },
      (response) => {
        if (response && response.data && response.data.PreFlightSurveyDetailRecordCount[0]) {
          // console.log(response)
          localStorage.setItem('recordcount', response.data.PreFlightSurveyDetailRecordCount[0].TotalCount);
          recordcount = response.data.PreFlightSurveyDetailRecordCount[0].TotalCount;
        }
      }
    );
  }
  return recordcount;
}

export function clearCacheData() {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
  return true;
}

export function saveFCMToke() {
  let params = {
    "UserDetailID": JSON.parse(localStorage.getItem('spydrloginObject'))?.UserDetailID,
    "webFcmToken": localStorage.getItem("FCMToken"),
    "CreatedBy": JSON.parse(localStorage.getItem('spydrloginObject'))?.UserDetailID
  }
  localStorage.getItem("FCMToken") && commonpostApiFunction('addMobileNotificationDetailWeb', params
    , (Res) => {
      localStorage.setItem("FCMTokenSave", true);
      return true;
    }
    , (onError) => {
      console.log(onError);
      return false;
    }
  );
  // console.log('Device token:', token);
}