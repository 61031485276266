import mock from "../mock"
const data = [
  {
    casenumber: "20WH 0000001",
    eventtype: "Creation",
    eventdescription: "Patient Detail Added",
    datavalue: "Patient Name",
    newvalue: "John Adams",
    oldvalue: "",
    username: "Ranjan Allen",
    datecreated: "2020-04-14"
  },
  {
    casenumber: "20WH 0000001",
    eventtype: "Creation",
    eventdescription: "Provider Detail Added",
    datavalue: "Provider Name",
    newvalue: "John S Wayne",
    oldvalue: "",
    username: "Ranjan Allen",
    datecreated: "2020-04-14"
  },
  {
    casenumber: "20WH 0000001",
    eventtype: "Creation",
    eventdescription: "Provider Detail Added",
    datavalue: "Primary Provider Name",
    newvalue: "Kevin Smith",
    oldvalue: "",
    username: "Ranjan Allen",
    datecreated: "2020-04-14"
  },
  {
    casenumber: "20WH 0000001",
    eventtype: "Creation",
    eventdescription: "Provider Detail Added",
    datavalue: "Submitting Facility",
    newvalue: "John Hopkins",
    oldvalue: "",
    username: "Ranjan Allen",
    datecreated: "2020-04-14"
  },
  {
    casenumber: "20WH 0000001",
    eventtype: "Creation",
    eventdescription: "Insurance Detail Added",
    datavalue: "Insurance Provider 1",
    newvalue: "HDFC Life Insurance",
    oldvalue: "",
    username: "Ranjan Allen",
    datecreated: "2020-04-14"
  },
  {
    casenumber: "20WH 0000001",
    eventtype: "Creation",
    eventdescription: "Insurance Detail Added",
    datavalue: "Insurance Provider 2",
    newvalue: "SBI Life Insurance",
    oldvalue: "",
    username: "Ranjan Allen",
    datecreated: "2020-04-14"
  }
]
mock.onGet("/api/aggrid/data").reply(200, {
  data
})
