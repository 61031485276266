import firebase from '@firebase/app';
import '@firebase/messaging';
const firebaseConfig = {
    apiKey: "AIzaSyALufpJ65GD9X4e4KDUWKseYj-2luoPqQ8",
    authDomain: "spotlight-69cf4.firebaseapp.com",
    databaseURL: "https://spotlight-69cf4-default-rtdb.firebaseio.com",
    projectId: "spotlight-69cf4",
    storageBucket: "spotlight-69cf4.appspot.com",
    messagingSenderId: "205244261736",
    appId: "1:205244261736:web:a7372c0bef098d21f862dd",
    measurementId: "G-DECK80ZSY9"
};
firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging();
const { REACT_APP_VAPID_KEY } = "BGr4p9N8iUCxI4gyWjkTlaOTY3KCdYWhuO9tXEtX1uxtt_u6FEdLm7c0CO8F-8Zxou3aL2-mWkrCFAqkK1TNt34";
const publicKey = REACT_APP_VAPID_KEY;
const requestNotificationPermission = async () => {
    try {
        let permission = await Notification.requestPermission();
        if (permission === "granted" && !localStorage.getItem("FCMToken")) {
            getDeviceToken();
        }
    } catch (error) {
        console.error('Error requesting notification permission:', error);
    }
};
const getDeviceToken = async () => {
    try {
        const token = await messaging.getToken({ vapidKey: publicKey });
        console.log('Device token:', token); // do not remove
        localStorage.setItem("FCMToken", token);
    } catch (error) {
        console.error('Error getting device token:', error);
    }
};
messaging.onMessage((payload) => {
    console.log('Message received:', payload);
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
        body: payload.notification.body,
    };
    if (!document.hidden) {
        const n = new Notification(notificationTitle, notificationOptions);
        // localStorage.setItem("notificationObject", n);
        n.onshow = (e) => {
            e.preventDefault();
            setTimeout(() => {
                n.close();
                // window.location.reload();
            }, 5000);
        }

    }
}
);
// requestNotificationPermission();
export const deleteFirebaseToken = async () => {
    const result = await messaging.deleteToken(localStorage.getItem("FCMToken"));
    console.log(result);
}



export default requestNotificationPermission;